import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Directive({ selector: '[datepicker]' })
export class DatepickerDirective {

    @Input() value = '';
    @Output() dateChange = new EventEmitter();

    constructor(element: ElementRef) {        
        let $obj = $(element.nativeElement);
        var defaultOptions = {
            "cancelClass": "btn-light",
            "applyButtonClasses": "btn-success",
            "locale": {
                format: 'DD/MM/YYYY',
            },
            "autoUpdateInput" : true
        };
        var objOptions = $.extend({}, defaultOptions, $obj.data());
        $obj.daterangepicker(objOptions);

        $obj.on('apply.daterangepicker', (ev, picker) => {
            let valueString = picker.startDate.format('DD/MM/YYYY');
            this.value = valueString;
            this.dateChange.next(valueString);
        });
    }
}   