import { Component, EventEmitter, OnInit, Output, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

/**
 * <switch-toggle [checked]="checkme" (switch)="onSwitch($event)"></switch-toggle>
 * 
 */
@Component({
    selector: 'switch-toggle',
    template: `
      <input [ngClass]="{'switchToggle': !global}" #switchToggle type="checkbox" [id]="id" data-switch="primary" [checked]="checked" [disabled]="disabled" />
      <label class="mb-0" [for]="id" data-on-label="'Oui'" data-off-label=""></label>
    `,
    styles: [' :host[disabled] { pointer-events: none; }']
})
export class SwitchToggleComponent implements OnInit {

  @ViewChild('switchToggle') switchToggle: ElementRef;

  // init:
  @Input() id: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() global = false;

  @Input()  switch!: boolean;
  @Output() switchChange = new EventEmitter<number>();
  @Output() switchEvent = new EventEmitter();

  ngOnInit() {   
  }

  @HostListener('click', ["$event"]) onMouseEnter(event: any) {
    console.log('Click toggle ', event);
    if (this.disabled) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.switchToggle.nativeElement.checked = !this.switchToggle.nativeElement.checked;
    this.emitEvents();
  }

  setChecked(checked) {
    this.checked = checked;
    this.switchToggle.nativeElement.checked = checked;
    this.emitEvents();
  }

  emitEvents() {
    this.switchEvent.next(this.switchToggle.nativeElement.checked);
    this.switchChange.next(this.switchToggle.nativeElement.checked);
  }

}