import { Component, OnInit} from '@angular/core';
import { StateService } from 'src/app/service/state.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(private router: Router, 
    private stateService: StateService) {
  
  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {


  }

}
