export class Counter {
  id: string;
  codeObjet: CodeObjectsMetiers;
  pattern: string;
  prefix: string;
  suffix: string;
  valeur: string;
  organisationId: string;
}

export enum CodeObjectsMetiers {
  COMMANDE = "COMMANDE",
  ARTICLE = "ARTICLE",
  TYPE_ARTICLE = "TYPE_ARTICLE",
  MANDAT = "MANDAT",
  ENGAGEMENT = "ENGAGEMENT",
  BORDEREAU = "BORDEREAU",
  RESERVATION = "RESERVATION",

  BUDGET = "BUDGET",

  ORDONNATEUR = "ORDONNATEUR",
  SALARIE = "SALARIE",

  ECRITURE_COMPTABLE = "ECRITURE_COMPTABLE",
  BALANCE_COMPTABLE = "BALANCE_COMPTABLE",

  FACTURE = "FACTURE",
  VIREMENT = "VIREMENT",
  PAIEMENT = "PAIEMENT",
  TYPE_DEPENSE = "TYPE_DEPENSE",
  CERTIFICAT_ADMINISTRATIF = "CERTIFICAT_ADMINISTRATIF",
  ORDRE_RECETTE = "ORDRE_RECETTE"
}