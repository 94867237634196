import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { forOwn } from "lodash";
import { ErrorHandlerService } from "../service/error-handler.service";
import { ErrorContainer } from "../model/ErrorContainer";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  activeRequests: number = 0;

  /**
   * URLs for which the loading should not be enabled
   */
  skippUrls = [
    '/refreshtoken',
  ];

  constructor(private errorHandlerService: ErrorHandlerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {

        console.error('The error response ', errorResponse);

        let reason = errorResponse && errorResponse.error && errorResponse.error.reason ? errorResponse.error.reason : '';
        let status = errorResponse.status;

        let errorContainer : ErrorContainer = new ErrorContainer();
        let errors = new Array<string>();
        
        if (status && (status == 400 || status == 500 ) && errorResponse.error.errors) {
          errorContainer.errorMessage = errorResponse.error.message;
          forOwn( errorResponse.error.errors, function(value, key) {
            errors.push(`<b>${key}</b> ${value}`);
          });
        }
        else if (errorResponse.error.error) {
          errorContainer.errorMessage = errorResponse.error.error;          
          if (errorResponse.error.message)
            errors.push(errorResponse.error.message);
          if (errorResponse.error.error_description)
            errors.push(errorResponse.error.error_description);
        }
        else if (errorResponse.message) {
          errorContainer.errorMessage = errorResponse.error.name || `Error ${status}`;
          errors.push(errorResponse.message);
        }
        else {
          errorContainer.errorMessage = `Error ${status}`;
          errors.push(errorResponse.message);
        }
        errorContainer.errors = errors;
        errorContainer.status = status || 0;

        this.errorHandlerService.error = errorContainer;
        return throwError(errorContainer);
      }));
  };
}

export const ErrorHandlerInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true }
];
