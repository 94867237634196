import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GestionForm, DataInitialization, InitialisationGestion, DataInitializationType, DomaineType, RepriseDonnee, GestionStatus } from './GestionForm';
import { StorageService } from '../../../service/storage.service';
import { MoisBudgetaire } from './mois-budgetaire';
import { GestionData } from './GestionData';
import { DroitUtilisateurDetails } from '../utilisateur/DroitUtilisateurData';

const headers = new HttpHeaders().set('Accept', 'application/json');

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class GestionService {

  private urlApi: string = environment.baseURLAPI + environment.baseConfigPort + 'configuration';
  private urlBudgetApi: string = environment.baseURLAPI + environment.baseBudgetPort + 'budgetaire';
  private urlGestionApi: string = environment.baseURLAPI + environment.baseConfigPort + 'gestion';

  constructor(private httpClient: HttpClient) {
  }

  getMoisBudgetaire(): Observable<MoisBudgetaire[]> {
    const url = `${this.urlApi}/mois-budgetaire`;
    return this.httpClient.get<MoisBudgetaire[]>(url);
  }

  getRights(accountId:string):  Promise<DroitUtilisateurDetails> {
    return this.httpClient.get<DroitUtilisateurDetails>( environment.baseURLAPI + environment.baseAccountPort + 'account/droits/feature-rights-action/' + accountId).toPromise();
  }

  getGestions(): Observable<GestionForm[]> {
    const url = `${this.urlApi}`;
    return this.httpClient.get<GestionForm[]>(url);
  }

  cloturerGestion(id: string): Promise<GestionForm> {
    const url = `${this.urlGestionApi}/` + id;
    return this.httpClient.post(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })).toPromise();
  }

  getCurrentGestion(): Observable<GestionForm> {
    const url = `${this.urlApi}/current`;
    return this.httpClient.get<GestionForm>(url);
  }

  createGestion(gestion: GestionForm): Promise<GestionForm> {
    const url = `${this.urlApi}/ouvrirGestion`;
    console.debug("gestion => ", gestion);
    return this.httpClient.post(url, gestion)
      .pipe(
        map((res: any) => {
          return res;
        })).toPromise();
  }

  initParametrage(): Promise<string> {
    const url = `${this.urlApi}/parametrage/gestion/init`;
    return this.httpClient.post<string>(url, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })).toPromise();
  }

  enregistrerDataInitialize(repriseData: RepriseDonnee) {
    let url = `${this.urlApi}/reprise/enregistrerDatas`;
    return this.httpClient.post<InitialisationGestion>(url, repriseData, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })).toPromise();
  }

  modifierStatutGestion(form: GestionForm): Promise<GestionForm> {
    let url = `${this.urlApi}`;
    return this.httpClient.put<GestionForm>(url, form, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })).toPromise();
  }

  reprendreDonnees(dataType: DomaineType, repriseData: RepriseDonnee): Promise<string> {
    if (dataType == DomaineType.BUDGETAIRE) {
      let url = `${this.urlBudgetApi}/reprise`;
      return this.httpClient.post<string>(url, repriseData, { headers })
        .pipe(
          map((res: any) => {
            return res;
          })).toPromise();
    } else {
      let url = `${this.urlApi}/reprise`;
      return this.httpClient.post<string>(url, repriseData, { headers })
        .pipe(
          map((res: any) => {
            return res;
          })).toPromise();
    }
  }

  loadParametrageGestion(): Promise<DataInitialization[]> {
    const url = `${this.urlApi}/parametrage/gestion`;
    return this.httpClient.get<DataInitialization[]>(url).pipe(
      map((result: DataInitialization[]) => {
        return result;
      })
    ).toPromise();
  }


  loadInitialisationGestion(gestionId: string): Promise<InitialisationGestion[]> {
    const url = `${this.urlApi}/reprise/` + gestionId;
    return this.httpClient.get<InitialisationGestion[]>(url).pipe(
      map((result: InitialisationGestion[]) => {
        return result;
      })
    ).toPromise();
  }
}
