import { Injectable } from '@angular/core';
import { GestionService } from '../modules/settings/gestion/gestion.service';
import { AuthService } from './auth.service';
//import { IdleService } from './idle.service';
import { StateService } from './state.service';
import { StorageService } from './storage.service';

/**
 * 
 */
@Injectable({
    providedIn: 'root'
})
export class AppSessionService {

    constructor(
        private gestionService: GestionService,
        private authService: AuthService,
        //private idleService: IdleService,
        private stateService: StateService,
        private storageService: StorageService) { }

    load(): Promise<string> {
        return new Promise<string>((resolve, reject) => {            
            let token = this.storageService.getToken();    
            let accountId = this.storageService.getAccountId();    
            let firstUse = this.storageService.getFirstUse();   
            console.info(" firstUse => ", firstUse);        
            if (token && accountId) {
                this.gestionService.getRights(accountId).then(result => {                    
                    this.stateService.getStateStore().setState({
                        droits: result,
                        startWatching: true
                    });                
                }).then(() => {                    
                    this.gestionService.getCurrentGestion().subscribe(gestion => {                        
                        if (gestion) {
                            this.storageService.updateGestion(gestion);
                            this.stateService.getStateStore().setState({
                                gestion: gestion,
                                firstConnection: firstUse
                            });
                        }else {
                            console.error('Erreur de chargement de la gestion courante pour le compte ', accountId);
                        }                             
                        //this.idleService.startWatch();
                        resolve(token);                  
                    });                
                }).catch(e => {
                    console.error('Erreur de chargement de données de la session' + e);
                    // this.authService.logout();
                    resolve(token);
                });
            }else {
                resolve('token & account id null');
            }
        });
    }

}
