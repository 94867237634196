import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ErrorHandlerService } from "../../../service/error-handler.service";
import { Subscription } from "rxjs";
import { ErrorContainer } from 'src/app/model/ErrorContainer';
declare var $: any;

@Component({
  selector: 'error-viewer',
  templateUrl: './error-viewer.component.html',
  styleUrls: ['./error-viewer.component.scss']
})
export class ErrorViewerComponent implements OnInit, OnDestroy {


  @Input() 
  error: ErrorContainer;
  errorSubscription: Subscription;
 
  constructor(private errorHandlerService: ErrorHandlerService) {
  }

  ngOnInit() {
    console.debug('init ErrorViewerComponent');
    this.errorSubscription = this.errorHandlerService.errorsAuthStatus.subscribe((value) => {
      console.debug('Error details ', value);
      if (value && value.errors && value.errors.length) {
        this.error = value;
      }
    });
  }

  ngOnDestroy() {
    // this.errorSubscription.unsubscribe();
  }

}