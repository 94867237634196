import { AfterViewInit, Component, EventEmitter, OnInit, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Counter, CodeObjectsMetiers } from 'src/app/modules/settings/counter/counter';
import { CounterFilter } from 'src/app/modules/settings/counter/counter-filter';
import { CounterService } from 'src/app/service/counter.service';
declare var $ : any;

@Component({
    selector: 'search-entity',
    templateUrl: './search-entity.component.html',
    styleUrls: ['./search-entity.component.scss']
})
export class SearchEntityComponent implements OnInit, AfterViewInit {
    
    inputModel: string;

    counterList: Counter[];
    objetMetiersRegExp : Map<CodeObjectsMetiers, string>;
    @Output() messageEvent = new EventEmitter<Object>();

    @ViewChild('box') input: ElementRef<HTMLInputElement>;
    listObjects: any;

    urlsObjetcs : Map<CodeObjectsMetiers, string> = new Map();

    constructor(private counterService: CounterService, private router: Router) { 
        this.urlsObjetcs.set(CodeObjectsMetiers.COMMANDE, '/commands/commande/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.ARTICLE, '/commands/article/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.TYPE_ARTICLE, '/commands/typeArticle/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.BORDEREAU, '/depense/bordereau-mandat/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.ENGAGEMENT, '/depense/engagement/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.MANDAT, '/depense/mandat/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.RESERVATION, '/depense/reservation-credit/edit');
        this.urlsObjetcs.set(CodeObjectsMetiers.ORDRE_RECETTE, '/depense/ordre-recette/edit');
    }

    ngAfterViewInit(): void {
        this.counterService.load(new CounterFilter()).then(res => {
            this.counterList = this.counterService.counterList;
            console.info('List counter ', this.counterService);
        }).then(() => {
            this.initSearch();
        });
    }

    ngOnInit() {
    }

    onChangeInputModel(value: string, $event) {
        var valueInput = value;
        if (valueInput && valueInput.length > 2) {
            //console.info(valueInput);
            var arrayObjectsMetier = Array<CodeObjectsMetiers>();
            this.objetMetiersRegExp.forEach((pattern, key) => {
                // console.info('March pattern ', pattern);
                if (pattern.indexOf(valueInput.substring(0,3)) != -1) {
                    //this.listObjects.push({ name: 'vklgkfsgfdnh ' + valueInput});                    
                    arrayObjectsMetier.push(key);
                }
            });
            this.counterService.findEntities(arrayObjectsMetier, valueInput).then(res => {
                console.info('Res search ', res);
                this.listObjects = res;
            });
        }else {
            this.listObjects = undefined;
        }
    }

    gotoEntity(item:any) {
        this.router.navigate([this.urlsObjetcs.get(item.codeObject)], { queryParams: { id: item.id } });
        this.clearSearch();
    }
    
    hasSearchResult() {
        return this.listObjects != null && this.listObjects.length > 0;
    } 

    clearSearch() {
        this.input.nativeElement.value = "";
        this.listObjects = undefined;
    }

    initSearch() {

        // Init object métier regexp
        this.objetMetiersRegExp = new Map();
        this.counterList.forEach(counter => {
            // transform pattern to regexp
            // ART-${GESTION}-${VALEUR} to /^ART\-*(.)*\-*(.)*$/gm
            var prefixSuffix = counter.pattern.split('${GESTION}-${VALEUR}');
            var regexpValue = '/^' + prefixSuffix[0] + '(.)*\-?(.)*' + prefixSuffix[1] + '/g';
            this.objetMetiersRegExp.set(counter.codeObjet, regexpValue);
        });
        console.info( this.objetMetiersRegExp);

        // Serach Toggle
        var navDropdowns = $('.navbar-custom .dropdown:not(.app-search)');

        // hide on other click
        $(document).on('click', (e) => {
            if($(e.target).closest('#search-dropdown').length === 0) {
                // $('#search-dropdown').removeClass('d-block');
                this.clearSearch();
            }
            return true;
        });

        // Serach Toggle
        /*
        $('#top-search').on('keyup', (e) => {
            e.preventDefault();
            var valueInput = e.target.value;
            if (valueInput && valueInput.length > 2) {
                //console.info(valueInput);
                var arrayObjectsMetier = Array<CodeObjectsMetiers>();
                this.objetMetiersRegExp.forEach((pattern, key) => {
                   // console.info('March pattern ', pattern);
                    if (pattern.indexOf(valueInput.substring(0,3)) != -1) {
                        //this.listObjects.push({ name: 'vklgkfsgfdnh ' + valueInput});                    
                        arrayObjectsMetier.push(key);
                    }
                });
                this.counterService.findEntities(arrayObjectsMetier, valueInput).then(res => {
                    console.info('Res search ', res);
                    this.listObjects = res;
                });
            }else {
                this.listObjects = undefined;
            }
            navDropdowns.children('.dropdown-menu.show').removeClass('show');
            // $('#search-dropdown').addClass('d-block');
            // return false;
        });
        */

        // hide search on opening other dropdown
        navDropdowns.on('show.bs.dropdown', function () {
            $('#search-dropdown').removeClass('d-block');
        });
    }

}