import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { LandingPageComponent } from './landing/landing.component';
import { LayoutComponent } from "./ui/layout/layout.component";

/**
 * Generated routes
 */
const routes: Routes = [
  {
    path:'',
    children: [
   { 
        path: 'commands', 
        canActivate: [AuthGuard],
        loadChildren: './modules/command/command.module#CommandModule'
      },
   { 
        path: 'depense', 
        canActivate: [AuthGuard],
        loadChildren: './modules/depense/depense.module#DepenseModule'
      }
      ,
      { 
        path: 'recette', 
        canActivate: [AuthGuard],
        loadChildren: './modules/recette/recette.module#RecetteModule'
      }
      ,
      { 
        path: 'edition', 
        canActivate: [AuthGuard],
        loadChildren: './modules/edition/edition.module#EditionModule'
      }
      ,
      { 
        path: 'facturation', 
        canActivate: [AuthGuard],
        loadChildren: './modules/facturation/facturation.module#FacturationModule'
      }
      ,
      { 
        path: 'paie', 
        canActivate: [AuthGuard],
        loadChildren: './modules/paie/paie.module#PaieModule'          
      }
      ,
      { 
        path: 'comptabilite', 
        canActivate: [AuthGuard],
        loadChildren: './modules/comptabilite/comptabilite.module#ComptabiliteModule'          
      },
      { 
        path: 'notes-annexes', 
        canActivate: [AuthGuard],
        loadChildren: './modules/notes-annexes/notes-annexes.module#NoteAnnexeModule'
      }
    ]
  }
];

const finalRoutes: Routes = [
  //{ path: '/home', component: HomeComponent },
  { 
    path: '', redirectTo:'dashboard', pathMatch: "full"
  },
  { path: 'landing', component: LandingPageComponent, data: {animation: 'AuthPage'} },
  { path: 'auth', component: AuthComponent, data: {animation: 'AuthPage'} },
  { 
    path: 'login', 
    component: LayoutComponent,
    children: [
      { path: '', component: AuthComponent }
    ]
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: './modules/settings/settings.module#SettingsModule'   
  },
  {
    path: 'dashboard',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule'   
  }, 
  ...routes[0].children
];

@NgModule({
  imports: [RouterModule.forRoot(finalRoutes, { useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
