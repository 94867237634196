import {DataTableParams} from './types/data-table-params.type';


export class DataTableResource<T> {
  
  _countItems = 0;
  result: T[] = [];

  constructor(private items: T[]) {
    this._countItems = this.items.length;
  }

  query(params: DataTableParams, filterCallback?: any, filters?: Object): Promise<T[]> {
    
    if (filterCallback) {
      this.result = filterCallback(this.items, filters);
    } else {
      this.result = this.items.slice(); // shallow copy to use for sorting instead of changing the original
    }

    this._countItems = this.result.length;

    if (params.sortBy) {
      this.result.sort((a: DataTableParams, b: DataTableParams) => {
        if (typeof a[params.sortBy] === 'string') {
          return a[params.sortBy].localeCompare(b[params.sortBy]);
        } else {
          return a[params.sortBy] - b[params.sortBy];
        }
      });
      if (params.sortAsc === false) {
        this.result.reverse();
      }
    }
    if (params.offset !== undefined) {
      if (params.limit === undefined) {
        this.result = this.result.slice(params.offset, this.result.length);
      } else {
        this.result = this.result.slice(params.offset, params.offset + params.limit);
      }
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(this.result));
    });
  }

  count(): Promise<number> {    
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(this._countItems));
    });
  }

}