import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';
import { IdleService } from '../service/idle.service'
import { GestionService } from '../modules/settings/gestion/gestion.service';
import { StateService } from '../service/state.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingPageComponent implements OnInit {
  emailValue: string ;
  userValue: string = '';
  passwordValue: string;
  emailDemoValue: string;
  clientNameValue: string;
  returnUrl: string;
  errMessage: string;
  otherError = false;
  credentialsError = false;
  loggedIn = false;
  hasResetRel = true;
  authenticationInProgress = false;
  submitted = false;
  oauthForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private idleService: IdleService,
    private gestionService: GestionService,
    private storageService: StorageService,
    private stateService: StateService) { }

  ngOnInit() {

    this.oauthForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required ]],  /* , Validators.pattern('^(?=.*[0-9]).{8,15}$')*/
    });

  }

  onSubmit($event) {

    this.submitted = true;
    
    // stop here if form is invalid
    if (this.oauthForm.invalid) {
      console.error('Error!! :-)\n\n' + JSON.stringify(this.oauthForm.value))
      return;
    }

    this.doAuth($event);

  }

  doAuth($event) {

    $event.preventDefault();
    this.authenticationInProgress = true;

    this.authService.logout();
    this.otherError = false;
    this.credentialsError = false;

    this.authService.authUser(this.oauthForm.value.email, this.oauthForm.value.password).subscribe(resource => {
      console.debug('AuthResource ', resource);

      // Sauvegarde le token et redirige vers la page de listing des démarches
      this.storageService.saveToken(resource.token, resource.refreshToken, resource.expiresIn, 
          resource.accountId, this.oauthForm.value.email, 
          resource.organisationId, resource.organisationName, resource.dateDerniereConnexion, resource.firstUse);
      this.loggedIn = true;
      this.idleService.startWatch();
      this.authenticationInProgress = false;
      this.handleAuthentifiedUser(resource);
    },
    err => {
      if(err.status === 401) {
        this.credentialsError = true;
      } else {
        this.otherError = true;
        this.errMessage = err.message;
      }
      this.authenticationInProgress = false;
    });
  }

  /**
   * Traitement à affectuer après l'authentification d'un utilisateur
   */
  handleAuthentifiedUser(resource:any) {

    this.authService.getRights(this.storageService.getAccountId()).then(result => {
      console.debug('rights =>', result);
      this.stateService.getStateStore().setState({
        droits: result,
        firstConnection: resource.firstUse,
        lastConnectionDate: resource.dateDerniereConnexion
      });
    });

    //this.authService.activateAccount(this.storageService.getAccountId()).;

    this.gestionService.getCurrentGestion().subscribe(gestion => {   
      console.debug('Init gestion par defaut : gestion => ', gestion);
      if(gestion){
        this.storageService.updateGestion(gestion);
        this.stateService.getStateStore().setState({
          gestion: gestion
        });
        this.router.navigate(['/dashboard/admin']);
      } else {
        this.router.navigate(['/dashboard/home']);
      }
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.oauthForm.controls; }
  
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  preventDefault($event) {
    $event.target.disabled = true;
  }
}
