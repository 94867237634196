import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnChanges {

    @Input() tooltip = '';

    constructor(private element: ElementRef) {        
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.tooltip) {
            this.element.nativeElement.title = this.tooltip;
            let $obj = $(this.element.nativeElement);            
            $obj.tooltip();
        }
    }
}   