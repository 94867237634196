import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DroitUtilisateurDetails, FeatureModule, FeatureModuleRightsAction } from 'src/app/modules/settings/utilisateur/DroitUtilisateurData';
import { StateService } from 'src/app/service/state.service';
declare var $: any;

@Directive({ selector: '[authorize]' })
export class AuthorizeDirective implements OnChanges {

    @Input() authorize : boolean = false;
    @Input() feature : FeatureModule;
    @Input() action : FeatureModuleRightsAction;

    droitsUtilisateur: DroitUtilisateurDetails;
  
    constructor(private element: ElementRef, private stateService: StateService) {        
        let $obj = $(element.nativeElement);
        this.stateService.getStateStore().select('droits').subscribe(      
            (droitsUtilisateur) => {                
                this.droitsUtilisateur = droitsUtilisateur;
            }
        ); 
    }

    replacer(key, value) {
      if(value instanceof Map) {
        return {
          dataType: 'Map',
          value: Array.from(value.entries()), // or with spread: value: [...value]
        };
      } else {
        return value;
      }
    }

    ngOnChanges(changes: SimpleChanges) {        
        let activeAction = false;
        if (changes.authorize && changes.feature && changes.action) {        
          if (this.droitsUtilisateur && this.droitsUtilisateur.droits && this.droitsUtilisateur.droits.hasOwnProperty(this.feature)
            && this.droitsUtilisateur.droits[this.feature].includes(this.action)) {
              activeAction = true;
          }  
        }else if (changes.authorize && (!changes.feature || !changes.action)) {
          activeAction = true;
        }
        if (!activeAction) {
          let el : HTMLElement = this.element.nativeElement;
          el.parentNode.removeChild(el);
        }
    }


}   