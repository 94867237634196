// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: '1.0.0',
  production: false,
  baseAPI: 'https://sigbuc.open-it.sn:8443/api/sigbuc/',
  baseURLAPI: 'https://sigbuc.open-it.sn:8443',
  baseAccountPort: '/api/sigbuc/',
  baseConfigPort: '/api/sigbuc/',
  baseBudgetPort: '/api/sigbuc/',
  baseComptabilitePort: '/api/sigbuc/',
  baseNotesPort: '/api/sigbuc/',
  apiVersion: 'api/', 
  clientId:'test',
  secret:'test'
};