import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { find, filter, pick, cloneDeep } from "lodash";

import { AuthService } from '../../service/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { GestionService } from '../../modules/settings/gestion/gestion.service';
import { StorageService } from '../../service/storage.service';
import { EncodedJwtToken } from "../../model/AuthResource";
import { StateService } from 'src/app/service/state.service';
import { GestionForm } from 'src/app/modules/settings/gestion/GestionForm';

declare var $: any;

@Component({
    selector: 'app-topbar',
    styleUrls: ['./topbar.component.scss'],
    templateUrl: './topbar.component.html' 
})
export class TopbarComponent implements OnInit, AfterViewInit {

    @Input() breadcrump : Array<any> = [];
    encodedJwtToken : EncodedJwtToken;
    activeSettings:boolean = false;
    gestion : GestionForm;
    gestionList : GestionForm[];

    constructor(private router: Router,         
        private authService: AuthService,
        private gestionService: GestionService,
        private storageService: StorageService,
        private userIdle: UserIdleService,
        private stateService: StateService) {  

    }
    
    ngAfterViewInit(): void {
        // $.Topbar.initSearch();
    }

    ngOnInit() { 
  
      this.encodedJwtToken = this.authService.getEncodedJwtToken();      

      this.gestionService.getGestions().subscribe(gestionList => {
        this.gestionList = gestionList;
        console.debug('Liste des gestions => ', this.gestionList);
      });

      this.stateService.getStateStore().select('gestion').subscribe(      
        (gestion) => {
          console.debug('Notification de la gestion par defaut ', gestion);    
          this.gestion = gestion;
        }
      ); 

    }

    switchGestion(gestion: GestionForm){
      
      this.storageService.updateGestion(gestion);
      this.gestion = gestion;

      this.stateService.getStateStore().setState({
        gestion: this.gestion
      });

      console.log("REDIRECTION VERS LA PAGE DASHBOARD !!!! ");
      $.NotificationApp.send("Changement de la gestion", 
        `La gestion courante est la suivante ${this.gestion.identifiantGestion}`, "bottom-right", "rgba(0,0,0,0.2)", "primary");

      this.router.navigate(['/dashboard/admin']);
    }


  disconnect() {   
    this.authService.logout();
    this.userIdle.stopWatching();
    this.router.navigate(['/auth']);
  }

  isLoggedIn() {
    return this.authService.isLogged();
  }

  isProfile() {
    return this.router.url.startsWith('/p');
  }

  callSearch() {
      // console.info("RESULT SEARCH => ", this.searchService.search());
  }

}