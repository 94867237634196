import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { LayoutPrivateComponent } from './layout/layout-private.component';
import { LayoutWrapperComponent } from './layout/layout-wrapper.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { UdDropdownComponent } from "./components/dropdown/dropdown.component";
import { UdDropdownItemComponent } from "./components/dropdown/dropdown-item.component";
import { UdIconComponent } from "./components/ud-icon/ud-icon.component";
import { ASideComponent } from "./aside/aside.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { RightBarComponent } from './rightbar/rightbar.component';
import { HttpLoadingComponent } from "./http-loading/http-loading.component";
import { HttpLoadingNotifComponent } from "./http-loading-notif/http-loading-notif.component";
import { DownloadFileComponent } from "./components/download-file/download-file.component";
import { DatepickerDirective } from "./components/datepicker/datepicker.directive";
import { TooltipDirective } from "./components/tooltip/tooltip.directive";

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// Datatable
// types & tools
import { CellCallback } from './components/datatable/types/cell-callback.type';
import { RowCallback } from './components/datatable/types/row-callback.type';
import { DataTableResource } from './components/datatable/data-table-resource';
import { DataTableParams } from './components/datatable/types/data-table-params.type';
// components & directives
import { DataTableComponent } from './components/datatable/datatable.component';
import { DataTableRowComponent } from './components/datatable/row.component';
import { DataTableColumnDirective } from './components/datatable/column.directive';
import { DataTableHeaderComponent } from './components/datatable/header.component';
import { DataTablePaginationComponent } from './components/datatable/pagination.component';
import { FilterBarComponent } from './components/datatable/filter-bar.component';
import { SearchEntityComponent } from './components/search-entity/search-entity.component';
import { SwitchToggleComponent } from './components/switch-toggle/switch-toggle.component';
import { ErrorViewerComponent } from './components/error-viewer/error-viewer.component';
import { AuthorizeDirective } from './components/authorization/authorize.directive';
import { SanitizedHtmlPipe } from './components/html-sanityze/html-sanityze.pipe';
import { OnboardingComponent } from "./components/onboarding/onboarding.component";
import { Select2Directive } from './components/select2/select2.directive';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export {
    DataTableResource, DataTableParams, CellCallback, RowCallback
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        LayoutComponent, LayoutPrivateComponent, LayoutWrapperComponent,
        HeaderComponent, FooterComponent, MenuComponent, RightBarComponent,
        UdDropdownComponent, UdDropdownItemComponent,
        UdIconComponent, ASideComponent, TopbarComponent, HttpLoadingComponent, HttpLoadingNotifComponent, DownloadFileComponent,
        DatepickerDirective, TooltipDirective, AuthorizeDirective,
        DataTableComponent, DataTableColumnDirective, DataTableRowComponent, 
        DataTablePaginationComponent, DataTableHeaderComponent,FilterBarComponent,
        SearchEntityComponent,
        SwitchToggleComponent,
        Select2Directive,
        ErrorViewerComponent, OnboardingComponent,
        SanitizedHtmlPipe
    ],
    exports: [
        LayoutComponent, 
        LayoutPrivateComponent, 
        LayoutWrapperComponent, 
        UdIconComponent, 
        FooterComponent, 
        RightBarComponent,
        ASideComponent, 
        TopbarComponent,
        HttpLoadingComponent,
        HttpLoadingNotifComponent,
        DownloadFileComponent,
        DatepickerDirective, TooltipDirective, AuthorizeDirective,
        DataTableComponent, DataTableColumnDirective, 
        SwitchToggleComponent,
        Select2Directive,
        ErrorViewerComponent, OnboardingComponent,
        SanitizedHtmlPipe
    ]
})
export class UiModule { }
