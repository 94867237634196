import {
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    ViewChild
  } from '@angular/core';
  import { DataTableComponent} from './datatable.component';
  
  let nextId = 0;
  
  @Component({
    selector: 'data-table-pagination',
    templateUrl: './html/pagination.component.html',
    styles: [`
      .pagination-rounded li:not(.active):not(.action) .page-link:hover{
        background-color: white !important;
      }
    `
    ]
  })
  export class DataTablePaginationComponent {
  
    id = `pagination-${nextId++}`;
    //@ViewChild('pageInput') pageInput: ElementRef;
    Math: any;
  
    @Input() limits: number[];
  
    constructor(@Inject(forwardRef(() => DataTableComponent)) public dataTable: DataTableComponent) {
      this.Math = Math;
    }
  
    pageBack() {
      this.dataTable.offset -= Math.min(this.dataTable.limit, this.dataTable.offset);
      if (this.dataTable.offset <= 0) {
        // this.pageInput.nativeElement.focus();
      }
    }
    
    pageForward() {
      this.dataTable.offset += this.dataTable.limit;
      if ((this.dataTable.offset + this.dataTable.limit) >= this.dataTable.itemCount) {
        // this.pageInput.nativeElement.focus();
      }
    }
  
    pageFirst() {
      this.dataTable.offset = 0;
      // this.pageInput.nativeElement.focus();
    }
  
    pageLast() {
      this.dataTable.offset = (this.maxPage - 1) * this.dataTable.limit;
      if ((this.dataTable.offset + this.dataTable.limit) >= this.dataTable.itemCount) {
        // this.pageInput.nativeElement.focus();
      }
    }
  
    get maxPage() {
      return Math.ceil(this.dataTable.itemCount / this.dataTable.limit);
    }
  
    get limit() {
      return this.dataTable.limit;
    }
  
    set limit(value) {
      this.dataTable.limit = +value;
      // returning back to the first page.
      this.page = 1;
    }
  
    get page() {
      return this.dataTable.page;
    }
  
    set page(value) {
      this.dataTable.page = +value;
    }
  
    validate(event) {
      const newValue = +event.target.value;
      if (newValue !== this.page) {
        this.page = (event.target.value > this.maxPage) ? this.maxPage : (newValue < 1 ) ? 1 : newValue;
        event.target.value = this.page;
      }
    }

    arrayOne(n: number): any[] {
      return Array(n);
    }
    
  }