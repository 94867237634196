import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { UiModule } from './ui/ui.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { UserIdleModule } from 'angular-user-idle';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';

import { TokenHeaderInterceptorProviders } from './interceptors/token.header.interceptor';
import { HttpLoadingInterceptorProviders } from './interceptors/http-loading.interceptor';
import { ErrorHandlerInterceptorProviders } from './interceptors/error-handler.interceptor';

import { AuthGuard } from './auth/auth.guard';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SecuredImageComponent } from './commons/secured-image/secured-image.component';
import { AuthService } from './service/auth.service';
import { StorageService } from './service/storage.service';
import { AppSessionService } from './service/session.service';
import { StateService } from './service/state.service';
import { AbstractDatatableComponent } from './core/components/abstract-datatable.component';
import { LandingPageComponent } from './landing/landing.component';

registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInit(appSessionService: AppSessionService) {
  return () => appSessionService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    LandingPageComponent,
    SecuredImageComponent,
    AbstractDatatableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    UiModule,
    BrowserModule,
    HttpClientModule,
    //PdfViewerModule,
    NgbTooltipModule,
    UserIdleModule.forRoot({
      idle: 600, 
      timeout: 600, 
      ping: 600
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    TokenHeaderInterceptorProviders,
    HttpLoadingInterceptorProviders,
    ErrorHandlerInterceptorProviders,
    CurrencyPipe, DecimalPipe,
    AppSessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppSessionService]
    }
  ],
  bootstrap: [AppComponent],
  exports: [SecuredImageComponent]
})
export class AppModule { }
