import { Injectable } from '@angular/core';
import { CodeObjectsMetiers, Counter } from '../modules/settings/counter/counter';
import { GestionForm } from '../modules/settings/gestion/GestionForm';

const TOKEN_KEY = 'APP_TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';
const ACCOUNT_ID_KEY = 'ACCOUNT_ID_KEY';
const ORGANISATION_ID_KEY = 'ORGANISATION_ID_KEY';
const PARTNER_KEY = 'APP_PARTNER_KEY';
const EXPIRES_IN = 'EXPIRES_IN';
const GESTION_CODE_KEY = 'GESTION_CODE_KEY';
const GESTION_ID_KEY = 'GESTION_ID_KEY';
const ACCOUNT_USERNAME_KEY = 'ACCOUNT_USERNAME_KEY';
const ORGANISATION_NAME_KEY = 'ORGANISATION_NAME_KEY';

export const CodeObjectsMetiersList: {
  key: string;
  value: string;
}[] = Object.entries(CodeObjectsMetiers)
  .map(([key, value]) => ({ key, value }));

/**
 * Service servant à gérer la sessionStorage, aucun service métier doit injecter doit le StorageService, 
 * etant donnée qu'il sert uniquement à stocker des données dans le sessionStorage ou le localStorage.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Constructeur du service
   */
  constructor(/* !!! Aucun service métier ne doit être injecté ici !!! */) { }

  // Suppression de toutes les données du sessionStorage.
  public clearAll() {
    sessionStorage.clear();
  }

  // Sauvegarde du token d'authentification à l'api.
  public saveToken(token: string, refreshToken: string, expiresIn: string,
    accountId: string, accountEmail: string,
    organisationId: string, organisationName: string, dateDerniereConnexion: string, firstUse: boolean) {
    this.clearToken();
    sessionStorage.setItem(TOKEN_KEY, token);
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    sessionStorage.setItem(ACCOUNT_ID_KEY, accountId);
    sessionStorage.setItem(ORGANISATION_ID_KEY, organisationId);
    sessionStorage.setItem(EXPIRES_IN, expiresIn);
    sessionStorage.setItem(ACCOUNT_USERNAME_KEY, accountEmail);
    sessionStorage.setItem(ORGANISATION_NAME_KEY, organisationName);
    sessionStorage.setItem("LAST_CONNEXION_DATE", dateDerniereConnexion);
    sessionStorage.setItem("FIRST_USE", "" + firstUse);
  }

  // update du temps d'expiration du token
  public updateExpiresIn(expiresIn: number) {
    sessionStorage.setItem(EXPIRES_IN, expiresIn.toString());
  }

  // Récupération du token d'authentification à l'api.
  public getFirstUse(): boolean {
    return sessionStorage.getItem("FIRST_USE") == "" + true;
  }

  // Récupération du token d'authentification à l'api.
  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
  }

  // Récupération du token d'authentification à l'api.
  public getAccountId(): string {
    return sessionStorage.getItem(ACCOUNT_ID_KEY);
  }

  // Récupération du token d'authentification à l'api.
  public getOrganisationId(): string {
    return sessionStorage.getItem(ORGANISATION_ID_KEY);
  }

  // Récupération du mail d'authentification à l'api.
  public getAccountEmail(): string {
    return sessionStorage.getItem(ACCOUNT_USERNAME_KEY);
  }

  public getExpiresTime(): number {
    return Number(sessionStorage.getItem(EXPIRES_IN));
  }

  // Suppression du token d'authentification à l'api (deconnection)
  public clearToken() {
    console.debug("Clear session informations.");
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(ACCOUNT_ID_KEY);
    sessionStorage.removeItem(EXPIRES_IN);
    sessionStorage.removeItem(ACCOUNT_USERNAME_KEY);
    sessionStorage.removeItem(ORGANISATION_ID_KEY);
    sessionStorage.removeItem(ORGANISATION_NAME_KEY);
    sessionStorage.removeItem(GESTION_ID_KEY);
  }

  // Sauvegarde du nom du partenaire.
  public savePartner(partner: string) {
    this.clearPartner();
    sessionStorage.setItem(PARTNER_KEY, partner);
  }

  // Récupération du nom du partenaire.
  public getPartnerName(): string {
    return sessionStorage.getItem(PARTNER_KEY);
  }

  //Suppression du nom du partenaire de la session.
  public clearPartner() {
    sessionStorage.removeItem(PARTNER_KEY);
  }

  public getProcedurePid() {
    return null;
  }

  public getGestionId() {
    return sessionStorage.getItem(GESTION_ID_KEY);
  }

  public getCodeGestion() {
    return sessionStorage.getItem(GESTION_CODE_KEY);
  }

  public updateGestion(gestion: GestionForm) {
    sessionStorage.removeItem(GESTION_ID_KEY);
    sessionStorage.removeItem(GESTION_CODE_KEY);
    sessionStorage.setItem(GESTION_ID_KEY, gestion.id);
    sessionStorage.setItem(GESTION_CODE_KEY, gestion.identifiantGestion);
  }

  // update du temps d'expiration du token
  public updateGestionId(gestionId: string) {
    sessionStorage.removeItem(GESTION_ID_KEY);
    sessionStorage.setItem(GESTION_ID_KEY, gestionId);
  }

  // update du temps d'expiration du token
  public updateCodeGestion(codeGestion: string) {
    sessionStorage.removeItem(GESTION_CODE_KEY);
    sessionStorage.setItem(GESTION_CODE_KEY, codeGestion);
  }

  // Sauvegarde du nom du partenaire.
  public setCounterInstance(counterInstanceKey: string, counter: Counter) {
    sessionStorage.setItem(counterInstanceKey, JSON.stringify(counter));
  }

  // Sauvegarde du nom du partenaire.
  public getCounterInstance(counterInstanceKey: string): Counter {
    let counter = sessionStorage.getItem(counterInstanceKey);
    if (counter) {
      return JSON.parse(counter);
    }
    return null;
  }

  // Sauvegarde du nom du partenaire.
  public invalidateCounterInstance(counterInstanceKey: string) {
    let counter = sessionStorage.getItem(counterInstanceKey);
    if (counter) {
      sessionStorage.removeItem(counterInstanceKey);
    }
  }

  // Sauvegarde du nom du partenaire.

  public setParam(key: string, value: string, session: boolean = true) {
    if (session) {
      sessionStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  public getParam(key: string, session: boolean = true): string {
    if (session) {
      return sessionStorage.getItem(key);
    } else {
      return localStorage.getItem(key);
    }
  }

}
