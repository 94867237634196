import { Component, OnInit, OnChanges, Input, HostListener, ElementRef, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

/**
 * how to used : 
 *  Ex: <download-file [href]="getFileUrl(...)" label="Télécharger le document"></download-file>
 *  Ex: <download-file href="http://..." [label]="textObject"></download-file>
 */
@Component({
  selector: 'download-file',
  template: `
    <i class="mdi mdi-file-pdf font-16 mr-1"></i><span *ngIf="label">{{label}}</span>
  `,
  styles: [
    `:host {
      cursor:pointer;
      width: 100%;
      display: block;
    }
    :host(:hover) {
      background-color: #317ecb;
      color: #ffffff;
    }
    `
  ]
})
export class DownloadFileComponent implements OnInit, OnChanges {

  @Input() public label: string;
  @Input() public href: string = '';
  private href$ = new BehaviorSubject(this.href);

  dataUrl$ = this.href$.pipe(switchMap(url => this.loadFileGet(url)));

  /**
   * 
   * @param el 
   * @param httpClient 
   * @param domSanitizer 
   */
  constructor(private el: ElementRef, private httpClient: HttpClient, private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.href$.next(this.href);
  };

  @HostListener('click', ['$event'])
  onClick(event) {
    console.info('Download-file onClick');
    this.dataUrl$.subscribe(blob => {
      /*
      const a = document.createElement('a');
      const objectUrl = this.domSanitizer.sanitize(
        SecurityContext.RESOURCE_URL, 
        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)));
      //const objectUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      console.info('objectUrl ', objectUrl);
      a.href = objectUrl;
      a.target = "_blank";
      a.click();
      URL.revokeObjectURL(objectUrl);
        */
      console.info('blob :', blob);
      window.open(URL.createObjectURL(blob), '_blank');
      //window.document.title = 'test.pdf';
    },
      error => {
        console.log('oops', error);
      });
  }

  private loadFileGet(url: string): Observable<any> {
    console.info('Download-file ', url);
    return this.httpClient
      // load the image as a blob
      .get(url, { responseType: 'blob' });
    // create an object url of that blob that we can use in the src attribute
    //.pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }

  private loadFilePost(url: string): Observable<any> {
    return this.httpClient
      // load the image as a blob
      .get(url, { responseType: 'blob' })
      // create an object url of that blob that we can use in the src attribute
      .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }

}
