
import { Component, ViewChild } from '@angular/core';

import { DataTableResource } from '../../ui/ui.module';
import { DataTableComponent } from '../../ui/components/datatable/datatable.component';


@Component({ template: '' })
export class AbstractDatatableComponent<T> {

    // Datatable data config
    itemResource: DataTableResource<T>;
    items = [];
    itemCount = 0;
    filters: any;
    @ViewChild(DataTableComponent) datatableComponent: DataTableComponent;

    reloadItems(params) {
        if (!this.itemResource)
            return;
        let promise;
        if (this.filters && this.filters[0].value) {
            const filters = this.buildFilters(this.filters[0].value);
            promise = this.itemResource.query(params, this.filterPlainArray, filters);
        } else {
            promise = this.itemResource.query(params);
        }
        promise.then(items => {
            this.items = items;
        }).then(() => {
            this.itemResource.count().then(count => this.itemCount = count);
        });
    }

    filterChanged(filters) {
        this.filters = filters;
        this.datatableComponent.reloadItems();
    }

    filterPlainArray(array, filters) {
        const filterKeys = Object.keys(filters);
        let getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);
        return array.filter(item => {
            // validates all filter criteria
            return filterKeys.some(key => {
                // ignores an empty filter
                if (!filters[key].length) return true;
                let findResult = filters[key].find(filter => {
                    let itemValue = getValue(item[key]);
                    let itemFilterValue = getValue(filter);
                    if (!itemValue) return false;
                    return itemValue.toString().indexOf(itemFilterValue) !== -1;
                });
                return findResult;
            });
        });
    }

    buildFilters(criteria: string): any {}
}