import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service/storage.service';
import { OrganisationData} from './OrganisationData';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private urlApi:string = environment.baseURLAPI + environment.baseAccountPort + 'account/organisation';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }

  countAll(): Observable<number> {
    const url = `${this.urlApi}/count`;
    return this.httpClient.get<number>(url);
  }

  getOrganisation(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.httpClient.get(url);
  }
  
  getOrganisationById(organisationId:any): Observable<OrganisationData> {
    const url = `${this.urlApi}/view/`;
    return this.httpClient.get<OrganisationData>(url + organisationId);
  }

  getOrganisationLogoById(organisationId:any): Promise<any> {
    const url = `${this.urlApi}/view/`;
    return this.httpClient.get<OrganisationData>(url + organisationId).pipe(
      map((res: any) => {
        return 'data:image/jpeg;base64,' + res.logo;
      })
    ).toPromise();;
  }

  createOrganisation(organisationData:OrganisationData): Promise<OrganisationData>{
	  const url = `${this.urlApi}`;
    console.info("organisationData 3 => ", organisationData);
    var formData = new FormData();
    formData.append( "id", organisationData.id );
    formData.append( "nom", organisationData.nom );
    formData.append( "description", organisationData.description );
    formData.append( "responsable", organisationData.responsable );
    formData.append( "siegeSocial", organisationData.siegeSocial);
    formData.append( "telephone", organisationData.telephone );
    formData.append( "titre1", organisationData.titre1 );
    formData.append( "titre2", organisationData.titre2 );
    formData.append( "titre3", organisationData.titre1 );
    formData.append( "titreOrdonnateur", organisationData.titreOrdonnateur);
    formData.append( "titreAdministrateurCredit", organisationData.titreAdministrateurCredit);
    formData.append( "nomBanqueDepositaire", organisationData.nomBanqueDepositaire);
    formData.append( "codeSwiftBanqueDepositaire", organisationData.codeSwiftBanqueDepositaire);
    formData.append( "numeroIbanBanqueDepositaire", organisationData.numeroIbanBanqueDepositaire);
    formData.append( "numeroCompteBancaireDepositaire", organisationData.numeroCompteBancaireDepositaire);
    if (organisationData.logo) {
      console.debug('"organisationData 4 => Set log to formdata')
      formData.append( "logo", organisationData.logo );
    }
    console.info("organisationData 5 formData => ", formData.get("logo"));
    if(organisationData.id){
      return this.httpClient.post<OrganisationData>(url + "/update", formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
    }else{
      return this.httpClient.post<OrganisationData>(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
    }
  }
}
