export class GestionForm {
   id: string;
   identifiantGestion: string;
   operationType: string;
   dateDebut: string;
   dateFin: string;
   dateDemarrage: string;
   dateInterruption: string;
   dateCloture: string;
   anneeBudgetaire: string;
   statutGestion: GestionStatus = GestionStatus.GESTION_CREATED;
}

export class RepriseDonnee {
   dataInitialisationList: DataInitializationType[];
   gestionSourceId: string;
   gestionCibleId: string;
}

export class InitialisationGestion {
   id: string;
   gestionId: string;
   dataType: DataInitializationType;
}

export class DataInitialization {
   id: string;
   dataType: DataInitializationType;
   titre: string;
   description: string;
   resource: DomaineType;
}

export enum GestionStatus {
   GESTION_CREATED = "GESTION_CREATED",
   GESTION_DEMARRE = "GESTION_DEMARRE",
   GESTION_ARRETER = "GESTION_ARRETER",
   GESTION_CLOS = "GESTION_CLOS",
   GESTION_OUVERTE = "GESTION_OUVERTE",
   GESTION_ARCHIVEE = "GESTION_ARCHIVEE"
}

export enum DomaineType {
   CONFIGURATION = "CONFIGURATION",
   BUDGETAIRE = "BUDGETAIRE"
}

export enum DataInitializationType {
   TYPE_DEPENSE,
   NOMENCLATURE,
   ACTES,
   SERVICE,
   TIERS,
   TYPE_ARTICLE,
   ARTICLE,
   ORGANISME_DEBITEUR,
   ELEMENT_SALAIRE,
   COMPTE_DEPOT,
   PARAMETRAGE_IPRESS,
   PARAMETRAGE_IR,
   PARAMETRAGE_CSS,
   PARAMETRAGE_HEURE_SUPP,
   PARAMETRAGE_TRIMF,
   DEMANDE_APPROVISONNEMENT,
   ELEMENT_COMPTABLE,
}