import {
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Inject
  } from '@angular/core';
  import { DataTableComponent } from './datatable.component';
  
  @Component({
    selector: 'data-table-header',
    templateUrl: './html/header.component.html'
  })
  export class DataTableHeaderComponent {
  
    columnSelectorOpen = false;
  
    constructor(@Inject(forwardRef(() => DataTableComponent)) public dataTable: DataTableComponent,
                private elemRef: ElementRef) {
    }
  
    @HostListener('document:click', ['$event']) onClickHandler(event) {
      if (!this.elemRef.nativeElement.contains(event.target)) {
        this.columnSelectorOpen = false;
      }
    }
  
    @HostListener('document:keyup', ['$event']) onKeyUpHandler(event) {
      if (event.keyCode === 27 || (event.keyCode === 9 && !this.elemRef.nativeElement.contains(event.target))) {
        this.columnSelectorOpen = false;
      }
    }
  
  }