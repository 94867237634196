import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { StateService } from 'src/app/service/state.service';
import { StorageService } from 'src/app/service/storage.service';
declare var $: any;

const INIT_ONBOARDIND_PARAM = 'INIT_ONBOARDIND_PARAM';

@Component({
  selector: 'onboarding',
  inputs: ['text'],
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnDestroy {

  onboardingSubscription: Subscription;
  currentStep: number = 1;

  constructor(private stateService: StateService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    console.debug('init OnboardingComponent');
    let initOnboarding = this.storageService.getParam(INIT_ONBOARDIND_PARAM)
    if (!initOnboarding) {
      this.onboardingSubscription = this.stateService.getStateStore().select('firstConnection').subscribe(      
        (firstConnection) => {        
          if (firstConnection) {
            console.debug('OnboardingComponent firstConnection ', firstConnection);     
              $('#onboarding-popup').modal('show');
          }else {
            $('#onboarding-popup').modal('hide');
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    this.onboardingSubscription.unsubscribe();
  }

  closeOnboarding() {
    this.storageService.setParam(INIT_ONBOARDIND_PARAM, INIT_ONBOARDIND_PARAM);
    $('#onboarding-popup').modal('hide');
  }


  nextStep() {
    var previousStep = 'step' + this.currentStep;
    var nextStep = 'step' + (++this.currentStep);
    $('.'+previousStep).removeClass('fade-in-image').addClass('fade-out-image');
    $('.'+nextStep).addClass('fade-in-image');
    setTimeout(() => {
      $('.'+previousStep).hide();
      $('.'+nextStep).show();
    }, 250);
    
  }

}