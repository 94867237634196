import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HomeService } from '../../service/home.service';
import { AuthService } from '../../service/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /**
   * Available languages
   */
  langs: any;
  currentLang: string;

  constructor(private router: Router, 
    private homeService: HomeService,
    private authService: AuthService,
    private userIdle: UserIdleService,
    private translateService: TranslateService) {  
      this.langs = translateService.getLangs();
  }

  ngOnInit() { 
    this.currentLang = this.translateService.currentLang;
  }

  disconnect() {   
    this.authService.logout();
    this.userIdle.stopWatching();
    this.router.navigate(['/home']);
  }

  isLoggedIn() {
    return this.authService.isLogged();
  }

  isProfile() {
    return this.router.url.startsWith('/p');
  }

  canSeeProfile() {
    //return true;
    /*return this.homeService.getCurrentHome().pipe(map(ret => {
        const home = new Resources(ret);
        return home.getRel('managers');
    }));
    */
  }

  useLanguage(selectedLanguage) {
    this.translateService.use(selectedLanguage);
    this.currentLang = this.translateService.currentLang;
  }

}
