import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

import { CodeObjectsMetiers, Counter } from '../modules/settings/counter/counter';
import { CounterFilter } from '../modules/settings/counter/counter-filter';

const headers = new HttpHeaders().set('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class CounterService {
  
  counterList: Counter[] = [];
  api = environment.baseURLAPI + environment.baseConfigPort + 'configuration/parametrage/numeroteur';
  apiSearch = environment.baseURLAPI + environment.baseBudgetPort + 'budgetaire/search';

  constructor(private http: HttpClient,
    private storageService: StorageService,) {
  }

  findById(id: string): Observable<Counter> {
    const url = `${this.api}/${id}`;
    return this.http.get<Counter>(url, {headers});
  }

  /*
    /api/depenses/search
  */
  findEntities(objectsMetiers:Array<CodeObjectsMetiers>, searchKey: string ): Promise<any> {
    const params = {
      patternList: objectsMetiers.join(','),
      searchKey: searchKey
    };
    return this.http.get<Counter>( this.apiSearch, {params, headers}).toPromise();
  }

  load(filter: CounterFilter): Promise<Counter[]> {
    return this.find(filter).pipe(
      map((result: Counter[]) => { 
        this.counterList = result;
        return result;
      })
    ).toPromise();
  }

  find(filter: CounterFilter): Observable<Counter[]> {
    const params = {
    };

    return this.http.get<Counter[]>(this.api, {params, headers});
  }

  save(entity: Counter): Observable<Counter> {
    let params = new HttpParams();
    let url = '';
    if (entity.id) {
      url = `${this.api}/${entity.id.toString()}`;
      params = new HttpParams().set('id', entity.id.toString());
      return this.http.put<Counter>(url, entity, {headers, params});
    } else {
      url = `${this.api}`;
      return this.http.post<Counter>(url, entity, {headers, params});
    }
  }

  saveForm(entityId: string, formdata: FormData): Observable<Counter> {
    let params = new HttpParams();
    let url = '';
    if (entityId) {
      url = `${this.api}/${entityId}`;
      params = new HttpParams().set('ID', entityId);
      return this.http.put<Counter>(url, formdata, {headers, params});
    } else {
      url = `${this.api}`;
      return this.http.post<Counter>(url, formdata, {headers, params});
    }
  }  

  delete(entity: Counter): Observable<Counter> {
    let params = new HttpParams();
    let url = '';
    if (entity.id) {
      url = `${this.api}/${entity.id.toString()}`;
      params = new HttpParams().set('ID', entity.id.toString());
      return this.http.delete<Counter>(url, {headers, params});
    }
    return null;
  }

  /**
   * Renvoie la prochaine valeur du numéroteur
   * 
   * @param organisationId L'identifiant d'une organisation
   * @param codeObjectsMetier Le code objet d'une entité métier
   * @returns L'objet Numeroteur <Counter>
   */
  getNextCounterForOrganisationAndCodeObject(codeObjectsMetier: CodeObjectsMetiers): Promise<Counter> {
    if (this.storageService.getOrganisationId()) {
      // Check if there is a counter already defined and not used for the organisaton and the object metier
      let counterInstanceKey = `${this.storageService.getOrganisationId()}-${codeObjectsMetier}`;
      let existingCounter = this.storageService.getCounterInstance(counterInstanceKey);
      if (!existingCounter) {
        const url = `${this.api}/${codeObjectsMetier}/next`;
        return this.http.get<Counter>(url, {headers}).pipe(
          map((counter: Counter) => { 
            this.storageService.setCounterInstance(counterInstanceKey, counter);
            return counter;
          })
        ).toPromise();        
      }else {
        return of(existingCounter).toPromise();
      }
    }else {
      throw "The organisation is not defined!";
    }
  }

  /**
   * Valide l'utilisation d'un numéro pour une entité métier donnée
   * @param codeObjectsMetier Code objet de l'entité métier
   */
  validateCounterForOrganisationAndCodeObject(codeObjectsMetier: CodeObjectsMetiers) {
    if (this.storageService.getOrganisationId()) {
      let counterInstanceKey = `${this.storageService.getOrganisationId()}-${codeObjectsMetier}`;
      this.storageService.invalidateCounterInstance(counterInstanceKey);
    }else {
      throw "The organisation is not defined!";
    }
  }

}

