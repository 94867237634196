/**
 * Section item of menu
 */
export interface MenuSectionItem {
  title: string,
  page: string,
  root?: boolean,
  icon?: string,
  svg?: string,
  translate?: string,
  bullet?: string,
  permission?: string,
  submenu?: Array<MenuSectionItem>
}

export interface MenuConfig {
  items: Array<MenuSectionItem>;
}

export const DynamicAsideMenuConfig: MenuConfig = {
  items: [
    {
      title: 'Paramétrage',
      root: true,
      icon: 'uil-cog',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: 'settings',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      submenu: [
        {
          title: 'Organisations',
          page: '/settings/organisation/list',
          permission: 'ORGANISATION'
        },
        {
          title: 'Gestions budgétaires',
          page: '/settings/gestion/create',
          permission: 'GESTION'
        },
        {
          title: 'Services',
          page: '/settings/structure/list',
          permission: 'SERVICE'
        },
        {
          title: 'Ordonnateurs',
          page: '/settings/ordonnateur/list',
          permission: 'ORDONNATEUR'
        },
        {
          title: 'Actes réglementaires',
          page: '/settings/actes',
          permission: 'ACTE_REGLEMENTAIRE'
        },
        {
          title: 'Tiers',
          page: '/settings/tiers/list',
          permission: 'TIERS'
        },
        {
          title: 'Nomenclatures',
          page: '/settings/nomenclature/list',
          permission: 'NOMENCLATURE'
        },
        {
          title: 'Type dépense',
          page: '/settings/type-depense/list',
          permission: 'TIERS'
        },
        {
          title: 'Budget',
          page: '/settings/budget/list',
          permission: 'DOTATION',
        },
        {
          title: 'Dotations',
          page: '/settings/dotation/list',
          permission: 'DOTATION'
        },
        {
          title: 'Mouvement budgétaire',
          page: '/settings/mouvement-budgetaire/list',
          permission: 'DOTATION'
        },
        {
          title: 'Bailleurs',
          page: '/settings/bailleurs/list',
          permission: 'DOTATION',
        },
        {
          title: 'Programmes Budgétaires',
          root: true,
          page: '/settings/programmes/list',
          permission: 'DOTATION'
        },
        {
          title: 'Répartition budget',
          page: '/settings/budget-service/list',
          permission: 'DOTATION'
        },
        {
          title: 'Profils',
          page: '/settings/profil/list',
          permission: 'PROFIL'
        },
        {
          title: 'Utilisateurs',
          page: '/settings/users/list',
          permission: 'UTILISATEUR'
        },
        {
          title: 'Numéroteurs',
          page: '/settings/counter/list',
          permission: 'GESTION'
        }
      ]
    },
    {
      title: 'Commandes',
      root: true,
      bullet: 'dot',
      page: 'commands',
      icon: 'uil-box',
      svg: './assets/media/svg/icons/Design/Cap-2.svg',
      submenu: [
        {
          title: 'Type article',
          bullet: 'dot',
          page: '/commands/typeArticle/list',
          permission: 'TYPE_ARTICLE'
        },
        {
          title: 'Article',
          bullet: 'dot',
          page: '/commands/article/list',
          permission: 'ARTICLE'
        },
        {
          title: 'Commandes',
          bullet: 'dot',
          page: '/commands/commande/list',
          permission: 'COMMANDE'
        }
        ,
        {
          title: 'Visa Rejet de Commandes',
          bullet: 'dot',
          page: '/commands/commande/visa-rejet',
          permission: 'COMMANDE'
        }
      ]
    },
    {
      title: 'Dépenses',
      root: true,
      bullet: 'dot',
      page: 'depense',
      icon: 'uil-bag-alt',
      svg: './assets/media/svg/icons/Shopping/Bitcoin.svg',
      submenu: [
        {
          title: 'Engagement',
          page: '/depense/engagement/list',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Visa Rejet engagement',
          page: '/depense/engagement/visa-rejet',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Certificat administratif',
          page: '/depense/certificat-administratif/list',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Recherche engagement',
          page: '/depense/engagement/search',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Mandatement',
          page: '/depense/mandat/list',
          permission: 'MANDAT_SAISIE'
        },
        {
          title: 'Visa Rejet de mandat',
          page: '/depense/mandat/visa-rejet',
          permission: 'MANDAT_SAISIE'
        },
        {
          title: 'Recherche de mandat',
          page: '/depense/mandat/search',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Demande approvisionnement',
          page: '/depense/demandeur/list',
          permission: 'NATURE_DEPENSE'
        },
        {
          title: 'Bordereau de mandat',
          page: '/depense/bordereau-mandat/list',
          permission: 'BORDEREAU_MANDAT'
        },
      ]
    },
    {
      title: 'Recette',
      bullet: 'dot',
      icon: 'uil-usd-square',
      svg: './assets/media/svg/icons/Shopping/Cart3.svg',
      root: true,
      permission: 'accessToECommerceModule',
      page: 'recette',
      submenu: [
        {
          title: 'Organisme débiteur',
          page: '/recette/organisme-debiteur/list',
          permission: 'ENGAGEMENT_ORDRE_RECETTE'
        },
        {
          title: 'Ordre de recette',
          page: '/recette/ordre-recette/list',
          permission: 'ENGAGEMENT_ORDRE_RECETTE'
        },
        {
          title: 'Recouvrement de recette',
          page: '/recette/ordre-recette/recouvrement/list',
          permission: 'ENGAGEMENT_ORDRE_RECETTE'
        },
        {
          title: 'Bordereau de recette',
          page: '/recette/bordereau-recette/list',
          permission: 'ENGAGEMENT_ORDRE_RECETTE'
        },
        {
          title: 'Réservation de crédit',
          page: '/recette/reservation-credit/list',
          permission: 'RESERVATION_CREDIT'
        },
        {
          title: 'Bordereau de réservation',
          page: '/recette/bordereau-reservation-credit/list',
          permission: 'RESERVATION_CREDIT'
        },
        {
          title: 'Régularisation de crédit',
          page: '/recette/reservation-credit/regularisation/list',
          permission: 'REGULARISATION'
        },
      ]
    },
    {
      title: 'Facturation',
      root: true,
      bullet: 'dot',
      icon: 'uil-receipt',
      svg: './assets/media/svg/icons/General/User.svg',
      page: 'facturation',
      submenu: [
        {
          title: 'Element Comptable',
          page: '/facturation/element-comptable/list',
          permission: 'TVA'
        },
        {
          title: 'Factures',
          page: '/facturation/facture/list',
          permission: 'PROFORMA'
        },
        {
          title: 'Prise en charge',
          page: '/facturation/facture/prise-en-charge',
          permission: 'PRISEEN_CHARGE'
        },
        {
          title: 'Validation factures',
          page: '/facturation/facture/validation',
          permission: 'VALIDATION_FACTURE'
        }
        ,
        {
          title: 'Bordereau facture',
          page: '/facturation/bordereau-facture/list',
          permission: 'SALAIRE'
        }
      ]
    },
    {
      title: 'Paie',
      root: true,
      bullet: 'dot',
      icon: 'uil-diary-alt',
      svg: './assets/media/svg/icons/Shopping/Box1.svg',
      page: 'paie',
      submenu: [
        {
          title: 'Personnel',
          page: '/paie/personnel/list',
          permission: 'PERSONNEL'
        },
        {
          title: 'Element de salaire',
          page: '/paie/elementSalaire/list',
          permission: 'ELEMENT_SALAIRE'
        },
        {
          title: 'Paramétrage Salaire',
          page: '/paie/salaire/parametrage',
          permission: 'SALAIRE'
        },
        {
          title: 'Salaires',
          page: '/paie/salaire/list',
          permission: 'SALAIRE'
        },
        {
          title: 'Paramétrage des AC',
          page: '/paie/compteDepot/list',
          permission: 'SALAIRE'
        },
        {
          title: 'Compte Fournisseur',
          page: '/paie/fournisseurs/edit',
          permission: 'SALAIRE'
        },
        {
          title: 'Virements Fournisseur',
          page: '/paie/fournisseurs/virements-list',
          permission: 'SALAIRE'
        },
        {
          title: 'Virements Salaire',
          page: '/paie/salaire/virements-list',
          permission: 'SALAIRE'
        },
        {
          title: 'Paiement caisse',
          page: '/paie/paiement-caisse/list',
          permission: 'BORDEREAU_MANDAT'
        },
      ]
    },
    {
      title: 'Editions',
      root: true,
      bullet: 'dot',
      icon: 'uil-presentation',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: 'edition',
      submenu: [
        {
          title: 'Situation crédit ordonne',
          page: '/edition/situation-credit-ordonne',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Situation engagement ligne',
          page: '/edition/situation-engagement-ligne',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Situation recettes recouvrés',
          page: '/edition/situation-recette-recouvre',
          permission: 'PREVISION'
        },
        {
          title: 'Charge',
          page: '/edition/charge',
          permission: 'CREDIT_ORDONNANCE'
        },
        {
          title: 'Situation recette ordonne',
          page: '/edition/situation-recette-ordonne',
          permission: 'RECETTES'
        },
        {
          title: 'Situation execution budgétaire',
          page: '/edition/situation-execution-budget',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Situation crédit engagés non ordonnés',
          page: '/edition/situation-credit-engage-non-ordonne',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Résultat gestion budgétaire',
          page: '/edition/resultat-gestion-budgetaire',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Produits',
          page: '/edition/produits',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Prévision recette dépense',
          page: '/edition/prevision-recette-depense',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
        {
          title: 'Situation crédit engagé',
          page: '/edition/situation-credit-engage',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
      ]
    },
    {
      title: 'Comptabilités',
      root: true,
      bullet: 'dot',
      icon: 'uil-puzzle-piece',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: 'comptabilite',
      submenu: [
        {
          title: 'Type de pièces',
          page: '/comptabilite/parametrage/type-piece/list',
          permission: 'PARAMETRAGE_JOURNAUX'
        },
        {
          title: 'Journaux comptable',
          page: '/comptabilite/parametrage/journaux-comptable/list',
          permission: 'PARAMETRAGE_JOURNAUX'
        },
        {
          title: 'Modes de réglement',
          page: '/comptabilite/parametrage/mode-reglement/list',
          permission: 'PARAMETRAGE_MODE_REGLEMENT'
        },
        {
          title: 'Types de retenues',
          page: '/comptabilite/parametrage/type-retenue/list',
          permission: 'TYPES_DE_RETENUES'
        },
        {
          title: 'Nomenclature Comptable',
          page: '/comptabilite/parametrage/nomenclature-comptable/list',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Mandat comptable',
          page: '/comptabilite/mandat-comptable/list',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Mandat comptable reçu',
          page: '/comptabilite/mandat-comptable/search',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Prise en charge de mandat',
          page: '/comptabilite/mandat-comptable/prise-en-charge',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Règlement de mandat',
          page: '/comptabilite/mandat-comptable/reglement',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'TVA précompté',
          page: '/comptabilite/tva-precompte/edit',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Rapprochement bancaire',
          page: '/comptabilite/rapprochement/list',
          permission: 'RAPPROCHEMENT_BANCAIRE'
        },
        {
          title: 'Ecriture comptable',
          page: '/comptabilite/ecriture-comptable/list',
          permission: 'ECRITURE_COMPTABLE'
        },
        {
          title: 'Consultation Ecriture comptable',
          page: '/comptabilite/ecriture-comptable/search',
          permission: 'ECRITURE_COMPTABLE'
        },
        {
          title: 'Importer Balance entrée',
          page: '/comptabilite/balance-comptable/import',
          permission: 'BALANCE'
        },
        {
          title: 'Balance d entrée',
          page: '/comptabilite/balance-comptable/edit',
          permission: 'BALANCE'
        },
      ]
    },
    {
      title: 'SICA',
      root: true,
      bullet: 'dot',
      icon: 'uil-briefcase',
      svg: './assets/media/svg/icons/Shopping/Box1.svg',
      page: 'sica',
      submenu: [
        {
          title: 'Paramètrage AC et compte de dépôt',
          page: '/wizards/wizard-1',
          permission: 'PARAMETRAGE_AC_COMPTE_DEPOT'
        },
        {
          title: 'Compte forunisseur',
          page: '/wizards/wizard-2',
          permission: 'COMPTE_FOURNISSEUR'
        },
        {
          title: 'Compte personnel',
          page: '/wizards/wizard-3',
          permission: 'COMPTE_PERSONNEL'
        },
        {
          title: 'Virement fournisseur',
          page: '/wizards/wizard-4',
          permission: 'VIREMENT_FOURNISSEUR'
        },
        {
          title: 'Virement salaire',
          page: '/wizards/wizard-4',
          permission: 'VIREMENT_SALAIRE'
        },
      ]
    },
    {
      title: 'Notes',
      root: true,
      bullet: 'dot',
      icon: 'uil-notes',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: 'notes',
      submenu: [
        {
          title: 'Note 1 : Dettes',
          page: '/notes-annexes/note1',
          permission: 'NOTE_1'
        },
        {
          title: 'Note 2 : Informations obligatoires',
          page: '/notes-annexes/note2',
          permission: 'NOTE_2'
        },
        {
          title: 'Note 3A : Immobilisations Brutes',
          page: '/notes-annexes/note3A',
          permission: 'NOTE_3'
        },
        {
          title: 'Note 3C : Immobilisations (Amortissements)',
          page: '/notes-annexes/note3C',
          permission: 'NOTE_3'
        },
        {
          title: 'Note 3D : Immobilisations plus value et moins value de cession',
          page: '/notes-annexes/note3D',
          permission: 'NOTE_3'
        },
        {
          title: 'Note 4 : Immobilisations financières',
          page: '/notes-annexes/note4',
          permission: 'NOTE_4'
        },
        {
          title: 'Note 5 : Créances et dettes HAO',
          page: '/notes-annexes/note5',
          permission: 'NOTE_4'
        },
        {
          title: 'Note 6 : Stock et encours',
          page: '/notes-annexes/note6',
          permission: 'NOTE_6'
        },
        {
          title: 'Note 7 : Client',
          page: '/notes-annexes/note7',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 8 : Autres créances',
          page: '/notes-annexes/note8',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 9 : Titres de placement',
          page: '/notes-annexes/note9',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 10 : Valeurs a encaisser',
          page: '/notes-annexes/note10',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 11 : Disponibilités',
          page: '/notes-annexes/note11',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 14 : Primes et réserves',
          page: '/notes-annexes/note14',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 15A : Total subventions et provisions reglementees',
          page: '/notes-annexes/note15A',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 15B : Autres fonds propres',
          page: '/notes-annexes/note15B',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 16A : Dettes financieres et ressources assimilees',
          page: '/notes-annexes/note16A',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 17 : Fournisseurs d\'exploitation',
          page: '/notes-annexes/note17',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 18 : Dettes fiscales et sociales',
          page: '/notes-annexes/note18',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 19 : Autres dettes et provisions pour risques à courts termes',
          page: '/notes-annexes/note19',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 20 : Banques, crédits d\'escompte et de de tresorerie',
          page: '/notes-annexes/note20',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 21 : Chiffre d\'affaire et autres produits',
          page: '/notes-annexes/note21',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 23 : Transports',
          page: '/notes-annexes/note23',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 24 : Services extérieurs',
          page: '/notes-annexes/note24',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 25 : Impots et taxes',
          page: '/notes-annexes/note25',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 26 : Autres charges',
          page: '/notes-annexes/note26',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 27 : Charges de personnel',
          page: '/notes-annexes/note27',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 28 : Provisions et dépréciations inscrites au bilan',
          page: '/notes-annexes/note28',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 29 : Charges et revenus financiers',
          page: '/notes-annexes/note29',
          permission: 'NOTE_7'

        },
        {
          title: 'Note 30 : Autres charges et produits HAO',
          page: '/notes-annexes/note30',
          permission: 'NOTE_7'

        }
      ]
    },
  ]

};