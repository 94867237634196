import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../service/auth.service';
import { EncodedJwtToken } from "../../model/AuthResource";
import { OrganisationData} from '../../modules/settings/organisation/OrganisationData';
import { OrganisationService } from '../../modules/settings/organisation/organisation.service';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss']
})
export class LayoutWrapperComponent implements OnInit {

  encodedJwtToken : EncodedJwtToken;
  organisationLogo: Promise<any>;
  hasSuperAdmin:boolean=false;

  constructor(public translate: TranslateService,    private organisationService: OrganisationService, private authService: AuthService) {
  }

  ngOnInit() {
    this.encodedJwtToken = this.authService.getEncodedJwtToken(); 
    this.hasSuperAdmin = this.encodedJwtToken.authorities.includes('SUPER_ADMIN');
    this.recupererLogoOrganisation(this.encodedJwtToken.organisationId);
  }
  
  recupererLogoOrganisation(organisationId:any){
    this.organisationLogo = this.organisationService.getOrganisationLogoById(organisationId);
  }

}