
/**
 * Authentication resource
 */
export class AuthResource {
  token: string;
  refreshToken: string;
  expiresIn: string;
  accountId: string | null;
  accountEmail: string | null;
  organisationId: string | null;
  organisationName: string | null;
  firstUse: boolean;
  dateDerniereConnexion: string | null;
  organisationLogo: any;
  
  constructor(init: any) {
    this.token = init ? init.token : '';
    this.refreshToken = init ? init.refreshToken : '';
    this.expiresIn = init ? init.expiresIn : '0'; 
    this.accountId = init ? init.accountId : null; 
    this.organisationId = init ? init.organisationId : null;
    this.organisationName = init ? init.organisationName : null;
    this.organisationLogo = init ? init.organisationLogo : null;
    this.accountEmail = init ? init.user_name : null; 
    this.dateDerniereConnexion = init ? init.dateDerniereConnexion : null;
    this.firstUse = init ? init.firstUse : false; 
  }

}

/**
 * Jwt Encoded Object
 */
export class EncodedJwtToken {
  accountId: string;
  authorities: Array<string>;
  email: string;
  username: string;
  organisationId: string;
  organisationName: string;
  organisationLogo: string;
  firstUse: boolean;
  dateDerniereConnexion: string;

  constructor(jwtToken: any) {
    this.accountId = jwtToken ? jwtToken.accountId : '';
    this.authorities = jwtToken ? jwtToken.authorities : []; 
    this.organisationId = jwtToken ? jwtToken.organisationId : '';
    this.organisationName = jwtToken ? jwtToken.organisationName : '';
    this.organisationLogo = jwtToken ? jwtToken.organisationLogo : '';
    this.firstUse = jwtToken ? jwtToken.firstUse : '';
    this.dateDerniereConnexion = jwtToken ? jwtToken.dateDerniereConnexion : '';
    this.email = jwtToken ? jwtToken.user_name : null; 
    this.username = jwtToken && jwtToken.nom ? `${jwtToken.prenom} ${jwtToken.nom}` : 'User UNDEFINED';
  }

}
