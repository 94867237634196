import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuService } from '../../service/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  filteredMenu: Observable<any[]>;

  constructor(private menuService: MenuService) {
  }

  ngOnInit() {
      this.filteredMenu = this.menuService.getMenu();
  }

  isLoggedIn() {
    return false;
  }
}
