import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';

import { AuthService } from '../service/auth.service';
import { StateService } from '../service/state.service';
import { StorageService } from '../service/storage.service';
import { ErrorContainer } from '../model/ErrorContainer';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  /**
   * ping en secondes
   */
  pingValue: number = 10; // 5 minutes

  /**
   * Timeout en secondes
   */
  timeOutValue: number = 1200; // 10 minutes

  /**
   * Idle time en secondes
   */
  idleTime: number = 600;

  constructor(
    private userIdle: UserIdleService,
    private storageService: StorageService,
    private authService: AuthService,
    private stateService: StateService,
    private errorHandlerService: ErrorHandlerService) {

      this.stateService.getStateStore().select('startWatching').subscribe(      
        (startWatching) => {                
            if (startWatching) {
              console.debug("Re-start watching...");
              this.startWatch();
            }
        }
    ); 
    }

  startWatch() {
    let token = this.storageService.getToken();
    if (token) {
      this.watch();
    }
  }

  private watch() {

    console.debug("Start watching...");

    this.userIdle.stopWatching();
    this.userIdle.stopTimer();

    this.userIdle.setConfigValues({ idle: this.idleTime, timeout: this.timeOutValue, ping: this.pingValue });

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(() => console.debug('Déconnexion imminente...'));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.debug("Déconnecté pour inactivité");
      this.logout();
    });

    this.userIdle.ping$.subscribe(() => {
      console.debug("Check token expiration time.", this.authService);
      let expiresTime = this.storageService.getExpiresTime();
      if (expiresTime) {
        var updatedExpiresTime = Number(expiresTime) - this.pingValue;
        this.storageService.updateExpiresIn(Number(updatedExpiresTime));
        // console.debug(`IdleService updatedExpiresTime: ${updatedExpiresTime} pingValue: ${this.pingValue}`);
        if (updatedExpiresTime < this.pingValue) {
          console.debug("Expiration du token, veuillez vous reconnecter!");

          let errorContainer : ErrorContainer = new ErrorContainer();
          let errors = new Array<string>();
          errorContainer.errorMessage = "Expiration de votre session utilisateur."
          errors.push("Votre session de connexion a expiré pour des raisons de sécurité.");
          errors.push("Veuillez vous authentifier à nouveau pour accéder à votre esapce de travail.");
          errorContainer.errors = errors;
  
          this.errorHandlerService.errorAuth = errorContainer;

          this.logout();
        }
      }
    });
  }

  private logout() {
    this.userIdle.stopWatching();
    this.userIdle.stopTimer();
    this.authService.logout();
  }

}
