import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { ErrorContainer } from '../model/ErrorContainer';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    private _error: ErrorContainer = null;
    errorsStatus: BehaviorSubject<ErrorContainer> = new BehaviorSubject(this._error);

    private _errorAuth: ErrorContainer = null;
    errorsAuthStatus: BehaviorSubject<ErrorContainer> = new BehaviorSubject(this._errorAuth);


    get error(): ErrorContainer {
        return this._error;
    }

    set error(value) {
        this._error = value;
        this.errorsStatus.next(value);
    }


    get errorAuth(): ErrorContainer {
        return this._errorAuth;
    }

    set errorAuth(value) {
        this._errorAuth = value;
        this.errorsAuthStatus.next(value);
    }

}