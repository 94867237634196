import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';

import { GestionService } from '../modules/settings/gestion/gestion.service';

import { StorageService } from '../service/storage.service';
import { environment } from '../../environments/environment';
import { AuthResource, EncodedJwtToken } from '../model/AuthResource';
import { DroitUtilisateurDetails } from '../modules/settings/utilisateur/DroitUtilisateurData';
import { Router } from '@angular/router';
import { HttpLoadingService } from './http-loading.service';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Ce service est dédié à la gestion de l'authentification et des droits d'un utilisateur
   * 
   * @param httpClient Se service permer de faire des requetes AJAX
   * @param storageService Se service permet de stocker des données dans le sessionStorage ou le localStorage
   */
  constructor(
    private router: Router,
    private httpClient: HttpClient, 
    private storageService: StorageService, 
    private gestionService: GestionService,
    private httpLoadingService: HttpLoadingService) {
  }

  /*
  environment.baseURLAPI + home.getRel('auth')
  */
  authUser(email, password): Observable<AuthResource> {

    let headers = new HttpHeaders()
      .append('Authorization','Basic ' + btoa( environment.clientId + ':' + environment.secret));

    let httpOptions = {
      headers: headers
    };

    const params = new HttpParams().append('grant_type','password')
      .append('username',email)
      .append('password',password);

    return this.httpClient.post( environment.baseURLAPI + environment.baseAccountPort + 'account/oauth', params, httpOptions)
      .pipe(
        map((res: any) => {
          return new AuthResource({token:res.access_token, refreshToken: res.refresh_token, expiresIn:res.expires_in, accountId: res.accountId, accountEmail: res.user_name, organisationId: res.organisationId, dateDerniereConnexion: res.dateDerniereConnexion, firstUse: res.firstUse});
        })
      );
  }


  refreshToken(): Observable<AuthResource> {

    let headers = new HttpHeaders()
    .append('Authorization','Basic ' + btoa( environment.clientId + ':' + environment.secret));

    let httpOptions = {
      headers: headers
    };

    const params = new HttpParams().append('grant_type','refresh_token')
      .append('refresh_token',  this.storageService.getRefreshToken())

    this.storageService.clearToken();

    return this.httpClient.post( environment.baseURLAPI + environment.baseAccountPort + 'account/oauth', params, httpOptions)
      .pipe(
        map((res: any) => {
          return new AuthResource({token:res.access_token, refreshToken: res.refresh_token, expiresIn:res.expires_in, accountId: res.accountId, accountEmail: res.user_name, organisationId: res.organisationId, dateDerniereConnexion: res.dateDerniereConnexion, firstUse: res.firstUse});
        })
      );
  }

  /**
   * 
   */
  validateAccount(email): Observable<boolean> {
    const params = new HttpParams().append('email',email);
    let httpOptions = {
      params: params
    };
    return this.httpClient.get( environment.baseURLAPI + environment.baseAccountPort + 'account/activate', httpOptions)
      .pipe(
        map((res: any) => {            
          return true;
        })
      );
  }

  getRights(accountId:string):  Promise<DroitUtilisateurDetails> {
    return this.httpClient.get<DroitUtilisateurDetails>( environment.baseURLAPI + environment.baseAccountPort + 'account/droits/feature-rights-action/' + accountId).toPromise();
  }

  getEncodedJwtToken(): EncodedJwtToken {
    if (this.storageService.getToken()) {
      var jwtToken = JSON.parse(atob(this.storageService.getToken().split(".")[1]));
      return new EncodedJwtToken(jwtToken);
    }
    return null;
  }

  isLogged(): boolean {
    return !!this.storageService.getToken();
  }

  logout() {
    this.httpLoadingService.stopLoading();
    this.storageService.clearToken();
    this.router.navigate(['/']);
  }

}
