import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';
import { IdleService } from '../service/idle.service'
import { ErrorHandlerService } from '../service/error-handler.service';
import { ErrorContainer } from '../model/ErrorContainer';

const TOKEN_HEADER_KEY = 'Authorization';
const X_USER_ID = 'X-USER-ID';
const X_USERNAME = 'X-USERNAME';
const X_GESTION_ID = 'X-GESTION-ID';
const X_ORGANISATION_ID ='X-ORGANISATION-ID';

@Injectable()
export class TokenHeaderInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService, 
    private authService: AuthService, private errorHandlerService: ErrorHandlerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.storageService.getToken();
    if (token !== null) {
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    const accountId = this.storageService.getAccountId();
    if (accountId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_USER_ID, accountId) });
    }

    const accountEmail = this.storageService.getAccountEmail();
    console.log("accountEmail => " + accountEmail);
    if (accountEmail !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_USERNAME, accountEmail) });
    }

    const gestionId = this.storageService.getGestionId();
    console.log("gestionId => " + gestionId);
    console.info("GESTION ID 1 ==========> ", this.storageService.getGestionId());
    if (gestionId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_GESTION_ID, gestionId) });
    }    

    const organisationId = this.storageService.getOrganisationId();
    console.log("organisationId => " + organisationId);
    if (organisationId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_ORGANISATION_ID, organisationId) });
    }  

    /* Le code pour faire un refresh du token
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.storageService.getRefreshToken()) {
          // Try refreshing the token if access token is expired
          return this.authService.refreshToken().pipe(
            switchMap((resource: any) => {
              console.debug('New Refresh AuthResource ', resource);
              this.storageService.saveToken(resource.token, resource.refreshToken, resource.expiresIn, 
                resource.accountId, null, 
                resource.organisationId, resource.organisationName, resource.dateDerniereConnexion, resource.firstUse);  
              this.idleService.startWatch();

              authReq = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${ this.storageService.getToken()}`
                }
              });
              return next.handle(authReq);
            }),
            catchError((err) => {
              this.authService.logout();
              return throwError(err);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
    */

    return next.handle(authReq).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 401) {
          
          let errorContainer : ErrorContainer = new ErrorContainer();
          let errors = new Array<string>();
  
          errorContainer.errorMessage = "Expiration de votre session utilisateur."
          errors.push("Votre session de connexion a expiré pour des raisons de sécurité.");
          errors.push("Veuillez vous authentifier à nouveau pour accéder à votre esapce de travail.");
  
          this.errorHandlerService.errorAuth = errorContainer;
          this.authService.logout();
        }
        // return throwError(error);
        return next.handle(authReq);
      })
    );

    //return next.handle(authReq);    
  }
}
 
export const TokenHeaderInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenHeaderInterceptor, multi: true }
];
