import { Component, forwardRef, Inject, OnInit } from '@angular/core';

import { Filter } from './types/filter.type';
import { FilterableField } from './types/filterable-field.type';
import { DataTableComponent } from './datatable.component';

@Component({
    selector: 'filter-bar',
    templateUrl: './html/filter-bar.component.html'
})
export class FilterBarComponent implements OnInit {

    filters: Filter[] = [];
    fields: FilterableField[];

    constructor(
        @Inject(forwardRef(() => DataTableComponent)) public dataTable: DataTableComponent) {
        this.fields = this.dataTable.filterableFields;
        if (dataTable.filters) {
            this.filters = dataTable.filters
        }
    }

    ngOnInit() {
    }

    inputFocused(event: Event) {
        (event.currentTarget as HTMLElement).blur();
    }

    filter(event:any) {
        let value = event.target.value.trim();
        let filterValue : Filter = {
            field: { header:'', property : 'criteria', dataType:'text' },
            operator: "contains",
            value: value
        };
        this.dataTable.filterChanged.emit([this.convertFilterToEmittedFilter(filterValue)]);
    }

    removeFilter(filter: Filter) {
        const index = this.filters.indexOf(filter);

        if (index >= 0) {
            this.filters.splice(index, 1);
            this.dataTable.filterRemoved.emit(this.convertFilterToEmittedFilter(filter));
            this.dataTable.filterChanged.emit(this.filters.map(c => this.convertFilterToEmittedFilter(c)));
        }
    }

    formatValue(filter: Filter) {
        let value = filter.value;
        if (filter.operator == 'empty') return '';

        if (filter.field.dataType == "enum") {
            value = (filter.value as any[]).map(v => typeof v === "string" ? v : v.displayText);
        }

        if (Array.isArray(value)) {
            value = value.join(', ');
        }

        return value;
    }

    private convertFilterToEmittedFilter(filter: Filter) {
        // clone the filter and extract values from enum possible options
        let clonedFilter = JSON.parse(JSON.stringify(filter)) as Filter;
        if (clonedFilter.operator == "empty") return clonedFilter;

        if (clonedFilter.field.dataType == "enum") {
            let valuesList = clonedFilter.value;
            for (let i = 0; i < valuesList.length; i++) {
                const value = valuesList[i];
                valuesList[i] = typeof value === "string" ? value : value.value
            }
        }
        return clonedFilter;
    }
}