export interface DroitUtilisateurData {
    accountId : string;
    profilIdList : string[];
}

export class DroitUtilisateurDetails {
    /**
     * Ex : { 'RECETTE_ROOT': ['SAISIE', 'VISER'], 'EDITION_ROOT': ['SAISIE']}
     */
    droits : {
        [Property in keyof FeatureModule]: Array<FeatureModuleRightsAction>
    }
}

export enum FeatureModule {

    RECETTE_ROOT="RECETTE_ROOT",
    COMMANDES_ROOT="COMMANDES_ROOT",
    DEPENSES_ROOT="DEPENSES_ROOT",
    EDITION_ROOT="EDITION_ROOT",
    NOTE_ROOT="NOTE_ROOT",
    SETTINGS_ROOT="SETTINGS_ROOT",
    PAIE_ROOT="PAIE_ROOT",
    COMPTABILITE_ROOT="COMPTABILITE_ROOT",
    FACTURATION_ROOT="FACTURATION_ROOT",
    PARAMETRAGE_AC_COMPTE_DEPOT="PARAMETRAGE_AC_COMPTE_DEPOT",
    COMPTE_FOURNISSEUR="COMPTE_FOURNISSEUR",
    COMPTE_PERSONNEL="COMPTE_PERSONNEL",
    VIREMENT_FOURNISSEUR="VIREMENT_FOURNISSEUR",
    VIREMENT_SALAIRE="VIREMENT_SALAIRE",
    ENGAGEMENT_ORDRE_RECETTE="ENGAGEMENT_ORDRE_RECETTE",
    RESERVATION_CREDIT="RESERVATION_CREDIT",
    CAISSE_AVANCE="CAISSE_AVANCE",
    REGULARISATION="REGULARISATION",
    PREVISION="PREVISION",
    CREDIT_ENGAGE="CREDIT_ENGAGE",
    CREDIT_ORDONNANCE="CREDIT_ORDONNANCE",
    RECETTES="RECETTES",
    SITUATION_EXERCICE_BUDGETAIRE="SITUATION_EXERCICE_BUDGETAIRE",
    NOTE_1="NOTE_1",
    NOTE_2="NOTE_2",
    NOTE_3="NOTE_3",
    NOTE_4="NOTE_4",
    NOTE_5="NOTE_5",
    NOTE_6="NOTE_6",
    NOTE_7="NOTE_7",
    NOTE_8="NOTE_8",
    NOTE_9="NOTE_9",
    NOTE_10="NOTE_10",
    NOTE_11="NOTE_11",
    NOTE_12="NOTE_12",
    NOTE_13="NOTE_13",
    NOTE_14="NOTE_14",
    NOTE_15="NOTE_15",
    NOTE_16="NOTE_16",
    NOTE_17="NOTE_17",
    NOTE_18="NOTE_18",
    NOTE_19="NOTE_19",
    NOTE_20="NOTE_20",
    NOTE_21="NOTE_21",
    NOTE_22="NOTE_22",
    NOTE_23="NOTE_23",
    NOTE_24="NOTE_24",
    NOTE_25="NOTE_25",
    ARTICLE="ARTICLE",
    NOTE_26="NOTE_26",
    NOTE_27="NOTE_27",
    NOTE_28="NOTE_28",
    NOTE_29="NOTE_29",
    NOTE_30="NOTE_30",
    ENGAGEMENT="ENGAGEMENT",
    ENGAGEMENT_SALAIRE="ENGAGEMENT_SALAIRE",
    MANDAT_SAISIE="MANDAT_SAISIE",
    NATURE_DEPENSE="NATURE_DEPENSE",
    DEPENSE_AGENT="DEPENSE_AGENT",
    VALIDATION_REJET_ENGAGEMENT="VALIDATION_REJET_ENGAGEMENT",
    BON_ENGAGEMENT="BON_ENGAGEMENT",
    BORDEREAU_MANDAT="BORDEREAU_MANDAT",
    GESTION="GESTION",
    ACTE_REGLEMENTAIRE="ACTE_REGLEMENTAIRE",
    NOMENCLATURE="NOMENCLATURE",
    ORDONNATEUR="ORDONNATEUR",
    TIERS="TIERS",
    UTILISATEUR="UTILISATEUR",
    PROFIL="PROFIL",
    DOTATION="DOTATION",
    SERVICE="SERVICE",
    PERSONNEL="PERSONNEL",
    ELEMENT_SALAIRE="ELEMENT_SALAIRE",
    SALAIRE="SALAIRE",
    ETATS="ETATS",
    PARAMETRAGE_JOURNAUX="PARAMETRAGE_JOURNAUX",
    PARAMETRAGE_MODE_REGLEMENT="PARAMETRAGE_MODE_REGLEMENT",
    TYPES_DE_RETENUES="TYPES_DE_RETENUES",
    TVA_PRECOMPTE="TVA_PRECOMPTE",
    RAPPROCHEMENT_BANCAIRE="RAPPROCHEMENT_BANCAIRE",
    ECRITURE_COMPTABLE="ECRITURE_COMPTABLE",
    BALANCE="BALANCE",
    TVA="TVA",
    PROFORMA="PROFORMA",
    FACTURE="FACTURE",
    PRISEEN_CHARGE="PRISEEN_CHARGE",
    VALIDATION_FACTURE="VALIDATION_FACTURE",
    COMMANDE="COMMANDE",
    TYPE_ARTICLE="TYPE_ARTICLE",
    ORGANISATION="ORGANISATION",
    SUPER_ADMIN="SUPER_ADMIN"
}

export enum FeatureModuleRightsAction {
    SAISIE = "SAISIE",
	VISER = "VISER",
	PRISE_EN_CHARGE = "PRISE_EN_CHARGE",
	RECEPTION = "RECEPTION",
	REGLEMENT = "REGLEMENT",	
	DEPENSE_DE_CAISSE = "DEPENSE_DE_CAISSE",
	CAISSE_AVANCE = "CAISSE_AVANCE"
 }