import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { DynamicAsideMenuConfig, MenuConfig, MenuSectionItem } from '../../core/configs/dynamic-aside-menu.config';

import { AuthService } from '../../service/auth.service';
import { EncodedJwtToken } from "../../model/AuthResource";

import { filter, flatMap } from "lodash";
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-aside',
    template: `
    <ul class="metismenu side-nav">
      <li class="side-nav-item">
        <a routerLink="/dashboard" class="side-nav-link">
          <i class="uil-home-alt"></i>
          <span> Tableau de bord </span>
        </a>
      </li>
      <ng-container *ngFor="let item of menuConfig.items">
        <ng-container
          *ngIf="item.title"
          [ngTemplateOutlet]="menu1LevelTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
      </ng-container>
    </ul>

    <!-- begin::1 Level -->
    <ng-template #menu1LevelTemplate let-item="item">    
      <li class="side-nav-item" *ngIf="hasSubMenu(item)" [ngClass]="{ 'mm-active':  isActive(item)}">
        <a href="javascript: void(0);" class="side-nav-link" [ngClass]="{ 'active':  isActive(item)}">
            <i [ngClass]="item.icon"></i>
            <span> {{ item.title }} </span>
            <span class="menu-arrow"></span>
        </a>

        <ng-container *ngIf="item.submenu">
          <ul class="side-nav-second-level" aria-expanded="false" [ngClass]="{ 'mm-show':  isActive(item)}">
            <ng-container 
              *ngFor="let sub of item.submenu"
              [ngTemplateOutlet]="menu2LevelTemplate"
              [ngTemplateOutletContext]="{ item: sub, parent: item }"
            ></ng-container>
          </ul>
        </ng-container>

      </li>
    </ng-template>
    
    <ng-template #menu2LevelTemplate let-item="item" let-parent="parent">
      <li *ngIf="hasPermission(item)" routerLinkActive="mm-active">
        <a [routerLink]="item.page" routerLinkActive="active">{{ item.title }}</a>
      </li>
    </ng-template>
    `,
    styleUrls: ['./aside.component.scss']
})
export class ASideComponent implements OnInit, AfterViewInit {
    
    menuConfig: MenuConfig;
    encodedJwtToken : EncodedJwtToken;
    currentUrl: string;
    page: string;

    constructor(private router: Router,
      private eRef: ElementRef, private authService: AuthService,
      private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
      this.menuConfig = DynamicAsideMenuConfig;
      this.encodedJwtToken = this.authService.getEncodedJwtToken();  
      this.activatedRoute.parent.url.subscribe((urlPath) => {
        const url = urlPath[urlPath.length - 1].path;
        this.page = url;
      });
    }

    ngAfterViewInit(): void {
      $.LeftSidebar.initMenu();
      $.LeftSidebar.activateDarkTheme();
    }
  
    hasPermission(item: MenuSectionItem): boolean {
      if (item.permission) {              
        return this.encodedJwtToken.authorities.includes(item.permission);
      }
      return true;
    }

    hasSubMenu(item: MenuSectionItem): boolean {
      let permissions = filter(
        flatMap(item.submenu, (itemSubMenu) => itemSubMenu.permission ), 
        (permission) => this.encodedJwtToken.authorities.includes(permission)
      );
      return permissions && permissions.length > 0;
    }

    isActive(item: MenuSectionItem) {
      return item.page == this.page;
    }

}
