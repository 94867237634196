import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppStore } from '../model/AppStore';

export class StateStore<StateType = AppStore> {
    
    private stateSubject: BehaviorSubject<StateType>;

    // Initialize the state store with the given initial state value.
    constructor( initialState: StateType ) {
        this.stateSubject = new BehaviorSubject( initialState );            
    }

    // Get the current state as a stream (will always emit the current state value as
    // the first item in the stream).
    public getState(): Observable<StateType> {
        return (this.stateSubject.pipe( distinctUntilChanged() ));
    }

    // Get the current state snapshot.
    public getStateSnapshot() : StateType {
        return( this.stateSubject.getValue() );
    }

    // Return the given top-level state key as a stream (will always emit the current
    // key value as the first item in the stream).
    public select<K extends keyof StateType>( key: K ) : Observable<StateType[K]> {
        var selectStream = this.stateSubject.pipe(
            map(
                ( state: StateType ) => {
                    return( state[ key ] );
                }
            ),
            distinctUntilChanged()
        );
        return( selectStream );
    }

    // Move the store to a new state by merging the given partial state into the
    // existing state (creating a new state object).
    // --
    // CAUTION: Partial<T> does not currently project against "undefined" values. This is
    // a known type safety issue in TypeScript.
    public setState( partialState: Partial<StateType> ) : void {
        var currentState = this.getStateSnapshot();
        var nextState = Object.assign( {}, currentState, partialState );
        console.debug('Set next state : ', nextState);
        this.stateSubject.next( nextState );
    }

} 


/**
 * The app state service management. 
 */
/**
 * 
 */
@Injectable({
    providedIn: 'root'
  })
export class StateService {
 
    private stateStore: StateStore<AppStore>;
 
	constructor() {
         // Create a simple store for baby name selection.
        this.stateStore = new StateStore<AppStore>({
            stats: [],
            updated: false,
            gestion: null,
            droits: null,
            firstConnection: false,
            lastConnectionDate: null
        });
     }
     
     getStateStore(): StateStore<AppStore> {
         return this.stateStore;
     }
 
}