import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

declare var $: any;

@Directive({ selector: '[select2]' })
export class Select2Directive implements AfterViewInit, OnChanges {

    select2: any;

    @Input()
    placeholder: string;

    @Output()
    itemSelected = new EventEmitter<boolean>();

    constructor(private element: ElementRef, private control: NgControl) {    
    }

    ngAfterViewInit(): void {
        this.initializeSelect2();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initializeSelect2();
    }

    initializeSelect2() {
        let $obj = $(this.element.nativeElement);            
        this.select2 = $obj.select2(
            {
                placeholder: this.placeholder || 'Veuillez sélectionner'
            }
        );    
        this.select2.on('select2:select', (event) => {
            const selectedItem = event.params.data;            
            this.control.control.setValue(selectedItem.id);
            this.itemSelected.emit(selectedItem);
        });
        this.select2.on('select2:open', (event) => {
            this.control.control.markAsTouched();
        });
    }

    clear() {
        if (this.select2) {
            this.select2.val(null).trigger('change');
            this.control.control.setValue(null);
        }
    }

}   